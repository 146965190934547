import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import "react-calendar-timeline/lib/Timeline.css";
import { DashboardComponents } from "app/dashboard/models";
import { getActiveSatellite } from "app/shared/utils";
import { Text, Flex } from "primitives";
import { fetchJson } from "app/network/utils/fetch";

import { Table, TableHead, TableRow, TableCell, TableBody } from "components";

interface ConjunctionsTableBaseProps {
  options?: any;
}

interface ConjunctionsTableBaseState {
  conjunctions: any;
}

/**
 * Primary component to display the visibility windows.
 */
class ConjunctionsTableBase extends Component<
  ConjunctionsTableBaseProps,
  ConjunctionsTableBaseState
> {
  static defaultProps: any;

  constructor(props: ConjunctionsTableBaseProps) {
    super(props);
    this.state = {
      conjunctions: []
    };
  }

  componentWillMount() {
    this.fetchConjunctionsData();
  }

  fetchConjunctionsData() {
    fetchJson(
      "https://cors-anywhere.herokuapp.com/http://ec2-34-244-118-87.eu-west-1.compute.amazonaws.com:8080/latest_conj_data/46274",
      {}
    )
      .then((res: any) => {
        if (res && res.json) {
          this.setState({ conjunctions: res.json });
        }
      })
      .catch((err: any) => console.log("err", err));
  }

  renderTableHead() {
    return (
      <TableRow color="text.white">
        <TableCell width="auto">Secondary Object (NORAD ID)</TableCell>
        <TableCell width="auto">TCA</TableCell>
        <TableCell width="auto">Miss Distance (M)</TableCell>
        <TableCell width="auto">Relative Velocity (M/S)</TableCell>
      </TableRow>
    );
  }

  renderConjunctions() {
    const { conjunctions } = this.state;
    return Object.keys(conjunctions).map((key: string, index: number) => {
      const conjunction = conjunctions[key];
      return (
        <TableRow key={`conjunction#${index}`}>
          <TableCell>{parseInt(conjunction["Secondary Object"])}</TableCell>
          <TableCell>
            {moment.utc(conjunction["TCA"]).format("YYYY-MM-DD HH:mm:ss")}
          </TableCell>
          <TableCell>{conjunction["Miss Distance [m]"]}</TableCell>
          <TableCell>{conjunction["Relative Velocity [m/s]"]}</TableCell>
        </TableRow>
      );
    });
  }

  renderTable() {
    return (
      <>
        <Table data-testid="DataTableSimple">
          <TableHead>{this.renderTableHead()}</TableHead>
          <TableBody>{this.renderConjunctions()}</TableBody>
        </Table>
      </>
    );
  }

  render() {
    const { options } = this.props;

    return (
      <Flex
        color="text.default"
        flexDirection="column"
        my={4}
        overflow="visible"
      >
        {options.label && (
          <Text fontSize={18} m={1} bold>
            {options.label}
          </Text>
        )}
        {this.renderTable()}
      </Flex>
    );
  }
}

ConjunctionsTableBase.defaultProps = {
  ...DashboardComponents.ConjunctionsTable
};

const mapStateToProps = (state: any) => {
  return {
    selectedSatellite: getActiveSatellite(state.constellations.dashboard)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const ConjunctionsTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConjunctionsTableBase);
