import { DataTypeAll } from "app/dataSource/models";

interface DashboardComponent {
  componentName: string;
  allowedDataTypes: string[] | null;
  options: {};
}

interface DashboardComponentsBase {
  [key: string]: DashboardComponent;
  getByType?: any;
}

/**
 * Exports dashboard components configurations
 */
export const DashboardComponents: DashboardComponentsBase = {
  Globe: {
    componentName: "Globe",
    allowedDataTypes: DataTypeAll,
    options: {}
  },
  Label: {
    componentName: "Label",
    allowedDataTypes: DataTypeAll,
    options: {}
  },
  RealTimeTable: {
    componentName: "Real Time Table",
    allowedDataTypes: DataTypeAll,
    options: {}
  },
  HistoricalTable: {
    componentName: "Historical Table",
    allowedDataTypes: null,
    options: {}
  },
  RealTimeGraph: {
    componentName: "Real Time Graph",
    allowedDataTypes: null,
    options: {}
  },
  HistoricalGraph: {
    componentName: "Historical Graph",
    allowedDataTypes: null,
    options: {
      graphDataView: true
    }
  },
  ProceduresExecution: {
    componentName: "Procedures Execution",
    allowedDataTypes: null,
    options: {}
  },
  ScriptExecutionList: {
    componentName: "Script Execution List",
    allowedDataTypes: null,
    options: {}
  },
  ScriptExecutionConsole: {
    componentName: "Script Execution Console",
    allowedDataTypes: null,
    options: {}
  },
  TelecommandsExecution: {
    componentName: "Telecommands Execution",
    allowedDataTypes: null,
    options: {}
  },
  TelecommandsSent: {
    componentName: "Telecommands Sent",
    allowedDataTypes: null,
    options: {
      page: 1,
      perPage: 20
    }
  },
  TelemetryReceived: {
    componentName: "Telemetry Received",
    allowedDataTypes: null,
    options: {}
  },
  ConstellationTree: {
    componentName: "ConstellationTree",
    allowedDataTypes: DataTypeAll,
    options: {}
  },
  VisibilityWindows: {
    componentName: "VisibilityWindows",
    allowedDataTypes: DataTypeAll,
    options: {}
  },
  PassageCountdown: {
    componentName: "PassageCountdown",
    allowedDataTypes: DataTypeAll,
    options: {}
  },
  ConjuctionsTable: {
    componentName: "Conjuctions Table",
    allowedDataTypes: DataTypeAll,
    options: {}
  }
};

DashboardComponents.getByType = (type: string) =>
  DashboardComponents[type] ? DashboardComponents[type] : {};

/**
 * Export all components. This is useful to interact with all choices.
 */
export const DashboardComponentsAll = Object.keys(DashboardComponents).filter(
  (component: string) => component !== "getByType"
);

export const DashboardComponentsNames = Object.values(DashboardComponents).map(
  (dashboard: { componentName: string }) => dashboard.componentName
);

export const DashboardComponentsDataTypesRequired = Object.keys(
  DashboardComponents
).filter((key) => {
  return Array.isArray(DashboardComponents[key].allowedDataTypes);
});

export const DashboardComponentsDataTypesNotRequired = Object.keys(
  DashboardComponents
).filter((key) => {
  return !Array.isArray(DashboardComponents[key].allowedDataTypes);
});

export interface Dashboard {
  id: string;
  applicableSatDefinition: ApplicableSatDefinition;
  name: string;
  description: string;
  type: Type;
  ownerId: string;
  permissions: Permissions;
  size: Size;
  components: Component[];
}

export interface ApplicableSatDefinition {
  id: number;
  name: string;
}

export interface Component {
  label: string;
  position: Size;
  size: Size;
  type: string;
  configOptions: null | string;
  dataSources: DashboardDataSourceDefinition[];
}

export interface DashboardDataSourceDefinition {
  datasourceId: number;
  label: string | null;
}

export interface Size {
  row: number;
  col: number;
}

export enum Permissions {
  Private = "Private",
  Public = "Public"
}

export enum Type {
  PerSatellite = "PerSatellite"
}
