import React, { PureComponent, FormEvent } from "react";
import { Box, Flex, Label, Button, Grid } from "primitives";
import { Switch } from "components";
import { Form } from "app/shared";
import { schemaGenerator, uiSchemaGenerator } from "../schemaGenerator";
import { TelecommandSpec, TelecommandFormData } from "../models";
import { TelecommandSendConfirmation } from "./TelecommandExecutionHelpers";
import objectPath from "object-path";
import debounce from "lodash/debounce";

interface TcCreationFormProps {
  initialFormData: TelecommandFormData;
  telecommandSpec: TelecommandSpec;
  convertToString: (formData: any, telecommandSpec: any) => any;
  satelliteId?: number;
  onSubmitTelecommandHandler: (data: any) => Promise<any>;
  defaultFieldsVisualization: string;
  automaticFill: boolean;
  sendConfirmation: boolean;
  preventMultiSend: boolean;
}

interface InternalFormState {
  formData: TelecommandFormData;
  loading: boolean;
  hideDefaultFields: boolean;
  showDefaultFieldsVisualizationSwitch: boolean;
  forceRefresh: boolean;
  newInitialFormData: boolean;
  telecommandSpec: TelecommandSpec | null;
  modalOpen: boolean;
}

export class TelecommandCreationForm extends PureComponent<
  TcCreationFormProps,
  InternalFormState
> {
  private tc = {};
  private curNode = null;
  private formData = null;
  private addButtonWorkAroundInterval = null;

  constructor(props: TcCreationFormProps) {
    super(props);
    this.state = {
      formData: null,
      loading: false,
      hideDefaultFields: false,
      showDefaultFieldsVisualizationSwitch: true,
      forceRefresh: false,
      newInitialFormData: false,
      telecommandSpec: null,
      modalOpen: false
    };
  }

  componentWillMount() {
    const { defaultFieldsVisualization } = this.props;
    if (defaultFieldsVisualization) {
      switch (defaultFieldsVisualization) {
        case "visible":
          this.setState({
            hideDefaultFields: false,
            showDefaultFieldsVisualizationSwitch: false
          });
          break;
        case "hidden":
          this.setState({
            hideDefaultFields: true,
            showDefaultFieldsVisualizationSwitch: false
          });
          break;
        case "defaultVisible":
          this.setState({
            hideDefaultFields: false,
            showDefaultFieldsVisualizationSwitch: true
          });
          break;
        case "defaultHidden":
          this.setState({
            hideDefaultFields: true,
            showDefaultFieldsVisualizationSwitch: true
          });
          break;
        default:
          break;
      }
    }

    (this.addButtonWorkAroundInterval as any) = setInterval(
      this.automaticallyClickAddButtons,
      50
    );
    this.setState({
      telecommandSpec: this.buildLocalTelecommandSpec(),
      formData: this.flattenFormData()
    });
  }

  componentWillUnmount() {
    clearInterval(this.addButtonWorkAroundInterval as any);
  }

  buildLocalTelecommandSpec() {
    const { telecommandSpec, automaticFill } = this.props;
    const { formData } = this.state;
    if (!telecommandSpec) {
      return null;
    }
    const newFormData = formData ? JSON.parse(JSON.stringify(formData)) : {};
    const newTelecommandSpec = JSON.parse(JSON.stringify(telecommandSpec));
    if (newTelecommandSpec) {
      newTelecommandSpec.args = [];
      const args = JSON.parse(JSON.stringify(telecommandSpec.args));
      if (args && newFormData) {
        this.tc = JSON.parse(JSON.stringify(newFormData));
        args.forEach((arg: any, index: number) => {
          if (!automaticFill) {
            this.removeDefaultValues(arg);
          }
          let builtArg = this.buildArg(arg);
          if (builtArg) {
            builtArg = this.removeNullValues(builtArg);
            newTelecommandSpec.args.push(builtArg);
          }
        });
      }
      return newTelecommandSpec;
    }
  }

  componentWillReceiveProps(nextProps: TcCreationFormProps) {
    // For now we're doing a poor man's deep equality check. in the future if this
    // type becomes more fully fleshed out, then we can implement an equals method;
    const next = JSON.stringify(nextProps.initialFormData);
    const current = JSON.stringify(this.state.formData);
    if (
      !this.props.telecommandSpec ||
      nextProps.telecommandSpec.id !== this.props.telecommandSpec.id ||
      (nextProps.initialFormData !== null && next !== current)
    ) {
      this.setState(
        {
          formData: nextProps.initialFormData,
          forceRefresh: true,
          newInitialFormData: true,
          telecommandSpec: this.buildLocalTelecommandSpec()
        },
        () => this.setState({ forceRefresh: false })
      );
    }
  }

  componentDidUpdate(prevProps: TcCreationFormProps) {
    const currentState: InternalFormState = this.state;
    const newInitialFormData = currentState.newInitialFormData;
    if (
      !currentState.telecommandSpec ||
      currentState.telecommandSpec.id !== prevProps.telecommandSpec.id ||
      newInitialFormData
    ) {
      this.setState(
        {
          telecommandSpec: this.buildLocalTelecommandSpec(),
          formData: this.flattenFormData(),
          newInitialFormData: false
        },
        () =>
          newInitialFormData &&
          this.setState({ telecommandSpec: this.buildLocalTelecommandSpec() })
      );
    }
  }

  render() {
    const {
      hideDefaultFields,
      showDefaultFieldsVisualizationSwitch
    } = this.state;

    return (
      <Grid data-testid="TelecommandCreationForm">
        {showDefaultFieldsVisualizationSwitch ? (
          <Flex alignItems="center" m={2}>
            <Flex mr={2}>
              <Label>Hide default fields:</Label>
            </Flex>
            <Switch
              defaultChecked={hideDefaultFields}
              onChange={(event) => this.toggleHideConstants(event)}
            />
          </Flex>
        ) : null}
        {hideDefaultFields && this.renderForm()}

        {/* Duplication needed to force form refresh when the schema is updated */}
        {!hideDefaultFields && this.renderForm()}
      </Grid>
    );
  }

  private renderForm() {
    const { satelliteId, sendConfirmation } = this.props;
    const {
      loading,
      telecommandSpec,
      formData,
      hideDefaultFields,
      forceRefresh,
      modalOpen
    } = this.state;
    if (!telecommandSpec) {
      return null;
    }
    let schema = schemaGenerator.generate(telecommandSpec);

    const extraProps = { satelliteId };
    const uiSchema = uiSchemaGenerator.generate(
      telecommandSpec,
      hideDefaultFields,
      extraProps
    );

    // Unfortunately, there isn't an easy way to force the form to re-render when the formData changes.
    // `forceRefresh` will help us in the following situation:
    // If the user clicks on the re-use button,
    // and edits the form and clicks again on the re-use button, the form will be updated correctly.
    // related issue: AURORA-1092
    return (
      !forceRefresh && (
        <Box position="relative">
          <Form
            id="telecommands"
            formData={formData}
            schema={schema}
            uiSchema={uiSchema}
            validate={this.formValidator}
            onSubmit={(data: any, e: any) =>
              sendConfirmation
                ? this.onFormSubmitWithConfirmation(data, e)
                : this.onFormSubmit(data, e)
            }
            onChange={debounce(({ formData: data }: any) => {
              this.onFormDataChange(data);
            }, 500)}
          >
            <Button type="submit" form="telecommands" disabled={loading} mt={2}>
              Send
            </Button>
          </Form>
          <TelecommandSendConfirmation
            cancel={() => this.setState({ modalOpen: false })}
            sendTelecommand={() => this.onFormSubmit(this.formData, null)}
            modalOpen={modalOpen}
          />
        </Box>
      )
    );
  }

  private onFormSubmitWithConfirmation(data: any, e: any) {
    e.preventDefault();
    this.formData = data;
    this.setState({ modalOpen: true });
  }

  private onFormSubmit(data: any, e: any) {
    const {
      onSubmitTelecommandHandler,
      telecommandSpec,
      preventMultiSend
    } = this.props;
    const modifiedData = {
      ...data,
      formData: {}
    };

    const parseGroupArg = (arg: any) => {
      if (
        arg.size &&
        typeof arg.size === "string" &&
        arg.size.startsWith("@")
      ) {
        const values: any = [];
        data.formData[arg.id].forEach((element: any, index: number) => {
          const value: any = this.checkData(
            { id: arg.id, value: element },
            arg.groupSpec
          );
          if (Object.keys(value).length > 1) {
            Object.keys(value).forEach((key: any, otherIndex: number) => {
              values.push(value[key][0]);
            });
          } else {
            values.push((value as any)[`${arg.id}[${index}]`][0]);
          }
        });
        return values;
      } else {
        const value = this.checkData(
          { id: arg.id, value: data.formData[arg.id] },
          arg.groupSpec
        );
        return value;
      }
    };

    telecommandSpec.args.forEach((spec: any) => {
      modifiedData.formData = {
        ...modifiedData.formData,
        [spec.id]:
          spec.argType === "Array"
            ? data.formData[spec.id]
                .split(",")
                .map((item: string) =>
                  this.checkData({ id: spec.id, value: item.trim() })
                )
            : spec.argType === "Group"
            ? parseGroupArg(spec)
            : this.checkData(
                { id: spec.id, value: data.formData[spec.id] },
                spec.groupSpec
              )
      };
    });
    try {
      this.setState({ loading: true, modalOpen: false }, () => {
        let cleanedModifiedData = this.removeNullValues(modifiedData);
        onSubmitTelecommandHandler(cleanedModifiedData);
        if (!preventMultiSend) {
          setTimeout(() => {
            this.setState({ loading: false });
          }, 1000);
        }
        this.formData = null;
      });
    } catch (err2) {
      // eslint-disable-next-line no-console
      console.log(err2);
      this.setState({ loading: false });
    }

    e && e.preventDefault();
  }

  private onFormDataChange(formData: any) {
    this.setState({ formData }, () =>
      this.setState({ telecommandSpec: this.buildLocalTelecommandSpec() })
    );
  }

  /**
   * Validates only the Literal and Arrays fields
   */
  private formValidator = (formData: any, errors: any) => {
    const {
      telecommandSpec: { args }
    } = this.props;
    Object.keys(formData).forEach((key: any) => {
      const spec: any = args.filter((arg: any) => {
        return arg.id === key;
      })[0];

      const itemType = spec.itemSpec ? spec.itemSpec.dataType : spec.dataType;
      // Check only for int and double types, at 'Literal' fields
      if (spec.argType === "Literal" && formData[key]) {
        switch (itemType) {
          case "int": {
            if (!this.isInt(formData[key])) {
              errors[key].addError(`Wrong type inserted.`);
            }
            break;
          }
          case "double": {
            if (!this.isDouble(formData[key])) {
              errors[key].addError(`Wrong type inserted.`);
            }
            break;
          }
        }
      }

      // Check the size and empty values at arrays fields
      if (
        spec.argType === "Array" &&
        (typeof spec.size == "string" || typeof spec.size == "number") &&
        formData[key]
      ) {
        let sizeNumber =
          typeof spec.size == "string"
            ? formData[spec.size.slice(1)] // To remove the "@"
            : spec.size;

        const values = formData[key].split(",");
        // Check if there's any empty values, if not, check if each of them is the right type
        if (values.length !== parseInt(sizeNumber, 10)) {
          errors[key].addError(`${spec.name} size doesn't match size number`);
        } else {
          for (let i = 0; i < values.length; i++) {
            if (values[i]) {
              switch (itemType) {
                case "int": {
                  if (!this.isInt(values[i])) {
                    errors[key].addError(
                      `Wrong type inserted, at position ${i}: ${values[i]}`
                    );
                  }
                  break;
                }
                case "double": {
                  if (!this.isDouble(values[i])) {
                    errors[key].addError(
                      `Wrong type inserted, at position ${i}: ${values[i]}`
                    );
                  }
                  break;
                }
              }
            } else {
              errors[key].addError(
                `Can't insert empty values. Please, check the inserted bytes, and try again.`
              );
              break;
            }
          }
        }
      }
    });

    return errors;
  };

  private removeDefaultValues = (arg: any) => {
    for (let objKey in arg) {
      if (objKey === "default") {
        delete arg[objKey];
      } else if (typeof arg[objKey] === "object") {
        this.removeDefaultValues(arg[objKey]);
      }
    }
  };

  private isInt(data: any): boolean {
    return isFinite(data) && !(data % 1);
  }

  private isDouble(data: any): boolean {
    return !isNaN(parseFloat(data));
  }

  private checkData(
    item: { id: string; value: string },
    groupSpec: any | null = null
  ): number | string | any[] {
    const {
      telecommandSpec: { args }
    } = this.props;

    const itemArgSpec: any = args.filter((arg: any) => {
      return arg.id === item.id;
    })[0];

    const type = itemArgSpec.itemSpec
      ? itemArgSpec.itemSpec.dataType
      : itemArgSpec.dataType;

    if (typeof item.value === "string") {
      if (type === "int") {
        const radix = item.value.startsWith("0x") ? 16 : 10;

        return parseInt(item.value, radix);
      }

      if (type === "double") {
        return item.value.startsWith("0x")
          ? item.value
          : parseFloat(item.value);
      }
    } else if (Array.isArray(item.value) && groupSpec) {
      //Order object parameters
      const resultObj: any = {};
      groupSpec.forEach((spec: any) => {
        resultObj[spec.id] = item.value[0][spec.id];
      });
      return [resultObj];
    } else if (item.value && typeof item.value === "object") {
      const checkGroup = (group: any, dataPath: any[]) => {
        if (Array.isArray(group) && group.length === 1) {
          dataPath.push(0);
          checkGroup(group[0], dataPath);
        } else if (group && typeof group === "object") {
          Object.keys(group).forEach((auxKey: string) => {
            dataPath.push(auxKey);
            checkGroup(group[auxKey], dataPath);
            dataPath.pop();
          });
        } else if (typeof group === "string") {
          const isArray = this.checkIfValueIsArray(groupSpec, dataPath);
          //split strings array in to array of strings
          if (isArray) {
            const splitedString = group.split(",");
            const finalValue: any[] = [];
            splitedString.forEach((string: any) => {
              if (!isNaN(string)) {
                finalValue.push(Number(string));
              } else {
                finalValue.push(string);
              }
            });
            objectPath.set(item.value as any, dataPath, finalValue);
          }
        }
      };
      Object.keys(item.value).forEach((key: string) => {
        checkGroup(item.value[key as any], [key]);
      });
    }
    return item.value;
  }

  private toggleHideConstants(event: FormEvent<HTMLInputElement>) {
    this.setState({
      hideDefaultFields: event.currentTarget.checked
    });
  }

  /**
   * Convert remote telecommand spec args to local telecommand spec format
   * - Flattens arrays
   * - Filters out inputs
   */

  private buildArg = (arg: any, path: string[] = []) => {
    if (!arg) {
      return null;
    }
    let formData = objectPath.get(this.tc, path);
    if (!formData) {
      formData = Object.assign({}, {});
    }
    /* eslint-disable no-unused-vars */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getParent = this.getParent;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getAbsolute = this.getAbsolute;
    /* eslint-enable no-unused-vars */
    if (arg.size && typeof arg.size === "string" && arg.size.startsWith("@")) {
      const sizeArg = arg.size.substr(1);
      const size = parseInt(this.findByKey(formData, sizeArg));
      if (!size || isNaN(size)) {
        return;
      }
      if (arg.argType === "Group") {
        //Flatten array
        const newGroupSpec: any = [];
        Array.from(Array(size)).forEach((x, i) => {
          const newId = `${arg.id}[${i}]`;
          const aux = {
            argType: JSON.parse(JSON.stringify(arg.argType)),
            id: newId,
            name: "",
            size: 1,
            groupSpec: JSON.parse(JSON.stringify(arg.groupSpec)),
            addable: false
          };
          newGroupSpec.push(aux);
        });
        arg.size = 1;
        arg.groupSpec = JSON.parse(JSON.stringify(newGroupSpec));
      } else if (arg.argType === "Array") {
        arg.size = size;
      }
    }
    if (arg.filter) {
      //Filter arguments
      this.curNode = objectPath.get(this.tc, path)
        ? objectPath.get(this.tc, path)[arg.id]
        : null;
      if (
        (!this.curNode || !getParent()) &&
        path.length > 0 &&
        path[path.length - 1] !== "0"
      ) {
        let auxPath: any = [].concat(path as any);
        auxPath.splice(auxPath.length - 1, 0, "0");
        if (objectPath.get(this.tc, auxPath)) {
          path = auxPath;
          if (objectPath.get(this.tc, auxPath)["0"]) {
            path.push("0");
          }
        } else {
          auxPath = [].concat(path as any);
          auxPath.splice(auxPath.length, 0, "0");
          if (objectPath.get(this.tc, auxPath)) {
            path = auxPath;
          }
        }
      }
      this.curNode = objectPath.get(this.tc, path)
        ? objectPath.get(this.tc, path)[arg.id]
        : null;
      if (!this.curNode || !getParent()) {
        const auxPath: any = [].concat(path as any);
        auxPath.push(arg.id);
        objectPath.set(this.tc, auxPath, {});
        this.curNode = objectPath.get(this.tc, auxPath);
      }
      // eslint-disable-next-line no-eval
      if (!eval(arg.filter)) {
        const auxPath: any = [].concat(path as any);
        auxPath.push(arg.id);
        if (objectPath.get(this.state.formData, auxPath)) {
          const stateFormData = JSON.parse(JSON.stringify(this.state.formData));
          objectPath.del(stateFormData, auxPath);
          this.setState({ formData: stateFormData });
        }
        return null;
      }
    }
    if (arg.argType === "Group") {
      path.push(arg.id);
      arg.groupSpec.forEach((groupArg: any, index: number) => {
        const buildArgRes = this.buildArg(groupArg, path);
        arg.groupSpec[index] = buildArgRes
          ? JSON.parse(JSON.stringify(buildArgRes))
          : null;
      });
      path.pop();
    }
    return arg;
  };

  private flattenFormData: any = () => {
    const { telecommandSpec, initialFormData } = this.props;
    if (!telecommandSpec || !initialFormData) {
      return;
    }
    const newFormData = Object.assign({}, initialFormData);
    const args = telecommandSpec.args;
    Object.keys(initialFormData).forEach((argKey: string) => {
      const arg = args.find((argAux: any) => argAux.id === argKey);
      if (
        arg &&
        arg.size &&
        typeof arg.size === "string" &&
        arg.size.startsWith("@")
      ) {
        const values = initialFormData[argKey];
        const newValues: any = {};
        values.forEach((value: any, index: number) => {
          newValues[`${argKey}[${index}]`] = [{ ...value }];
        });
        newFormData[argKey] = [newValues];
      }
      //Convert arrays to strings
      if (
        arg &&
        arg.argType === "Array" &&
        Array.isArray(initialFormData[argKey])
      ) {
        newFormData[argKey] = initialFormData[argKey].join(",");
      }
    });
    return newFormData;
  };

  //Returns object value for a given key
  private findByKey: any = (obj: any, key: string) => {
    let result;
    for (let property in obj) {
      /* eslint-disable no-prototype-builtins */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      if (obj.hasOwnProperty(property)) {
        /* eslint-enable no-prototype-builtins */
        // in case it is an object
        if (typeof obj[property] === "object") {
          result = this.findByKey(obj[property], key);

          if (typeof result !== "undefined") {
            return result;
          }
        } else if (property === key) {
          return obj[key]; // returns the value
        }
      }
    }
  };

  //Remove all null values from object
  private removeNullValues = (obj: any) => {
    Object.keys(obj).forEach(
      (key) =>
        (obj[key] &&
          typeof obj[key] === "object" &&
          this.removeNullValues(obj[key])) ||
        ((obj[key] === undefined || obj[key] === null) && delete obj[key])
    );
    return obj;
  };

  //WORKAROUND: Groups are being rendered as array fields of one element. React-jsonschema-form package required user to click "Add" button to show the fields. This method automatically clicks the "Add" buttons so the user doesnt have to.
  private automaticallyClickAddButtons = () => {
    const formGroups = Array.from(
      document.getElementsByClassName("form-group")
    );
    formGroups.forEach((formGroup) => {
      const button = formGroup.getElementsByTagName("button")[0];
      if (button) {
        button.click();
      }
    });
  };

  //Check if a value is an array by data path. Looks for value definition in the group spec
  private checkIfValueIsArray = (groupSpec: any, dataPath: any[]) => {
    let result = false;
    let currentGroupSpec = groupSpec;
    dataPath.forEach((key: string, index: number) => {
      const group = currentGroupSpec.find((gS: any) => gS.id === key);
      if (group && group.groupSpec) {
        currentGroupSpec = group.groupSpec;
      } else if (group && index === dataPath.length - 1) {
        if (group.argType === "Array") {
          result = true;
        }
      }
    });
    return result;
  };

  /*
   * AUX Functions for telecommand params filter
   */

  _getParent: any = (obj: any, value: any, parent: any) => {
    let p = null;
    if (obj === value) {
      p = parent;
    } else if (Array.isArray(value)) {
      for (let val of value) {
        p = this._getParent(obj, val, parent);
        if (p != null) {
          break;
        }
      }
    } else if (value && typeof value === "object") {
      /* eslint-disable no-unused-vars */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, val] of Object.entries(value)) {
        p = this._getParent(obj, val, value);
        if (p != null) {
          break;
        }
      }
      /* eslint-enable no-unused-vars */
    }
    return p;
  };

  getParent = (node = null) => {
    let p = null;
    if (node != null) {
      p = this._getParent(node, this.tc, null);
    } else {
      p = this._getParent(this.curNode, this.tc, null);
    }
    return p;
  };

  getAbsolute = (path: string) => {
    const getAbsoluteResult = objectPath.get(this.tc, path);
    if (getAbsoluteResult || getAbsoluteResult === 0) {
      return getAbsoluteResult;
    }
    const auxPath: any = path.split(".");
    auxPath.splice(1, 0, 0);
    return objectPath.get(this.tc, auxPath);
  };
}
