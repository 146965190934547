import React, { SFC, Component } from "react";
import { Flex, Box, Button, Icon, Text } from "primitives";
import { Toggler } from "components";
import {
  TelecommandExecutionStatus,
  TelecommandResponseStatus,
  ExecutedTelecommand,
  TelecommandPayload,
  TelecommandType,
  AuroraTelecommandPayload
} from "../models";
import { connect } from "react-redux";
import { fetchTelecommandResponse, clearTelecommandResponse } from "../actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { FramePayloadBodyGrid } from "app/telemetry/components/FramePayloadBodyGrid";
import { TelemetryFrame, AuroraTelemetryFrame } from "app/telemetry/models";
import { TabInput } from "app/shared";
import { FramePayloadAuxiliariesGrid } from "app/telemetry/components/FramePayloadAuxiliariesGrid";

/***
 * Telecommand List component: displays a list of telecommands, allowing the user to drill-down to the
 * details of a particular telecommand.
 */
type ResendTelecommandAction = (
  satId: number,
  telecommandExecutionPayload: AuroraTelecommandPayload
) => void;
type ReuseTelecommandAction = (
  telecommandRecordId: number,
  telecommandId: string,
  telecommandExecutionPayload: TelecommandPayload
) => void;
interface TelecommandListTableProps {
  executedTelecommands: ExecutedTelecommand[];
  resendTelecommandAction: ResendTelecommandAction;
  reuseTelecommandAction: ReuseTelecommandAction;
}
export const TelecommandsListTable: SFC<TelecommandListTableProps> = ({
  executedTelecommands,
  resendTelecommandAction,
  reuseTelecommandAction
}) => (
  <>
    <Box p={2} bg="fill.0" fontSize={2} color="text.default">
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Flex width="160px" />
        <Flex width="150px">Label</Flex>
        <Flex width="130px">Timestamp</Flex>
        <Flex width="100px">Ground Station</Flex>
        <Flex width="50px">Type</Flex>
        <Flex width="50px">Status</Flex>
        <Flex width="50px">Response</Flex>
        <Flex width="32px" />
      </Flex>
    </Box>

    {executedTelecommands &&
      executedTelecommands.map((it: ExecutedTelecommand) => {
        // High order function for each telecommand
        const telecommandId = it.telecommandExecutionPayload.telecommand.tcId;
        const telecommandRecordId = it.id;
        const telecommandPayload = it.telecommandExecutionPayload.telecommand;
        const resendTelecommand = () =>
          resendTelecommandAction(
            it.satId,
            AuroraTelecommandPayload.build(telecommandPayload)
          );

        const reuseTelecommand = () =>
          reuseTelecommandAction(
            telecommandRecordId,
            telecommandId,
            telecommandPayload
          );

        return (
          <Toggler key={it.id}>
            {([toggled, onToggle]) => (
              <Box p={2} bg="fill.1" fontSize={2}>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Flex width="160px">
                    <ResendButton onClick={resendTelecommand} />
                    <ReuseButton onClick={reuseTelecommand} />
                  </Flex>
                  <Flex width="150px">{it.tcLabel}</Flex>
                  <Flex width="130px">{it.sentAt}</Flex>
                  <Flex width="100px">{it.groundStation || "-"}</Flex>
                  <Flex width="50px">{it.tcType}</Flex>
                  <Flex width="50px">
                    <TelecommandSentStatusIndicator status={it.status} />
                  </Flex>
                  <Flex width="50px">
                    <TelecommandResponseIndicator
                      responseStatus={it.response}
                    />
                  </Flex>
                  <Flex width="32px" mr={1}>
                    <Button size="small" onClick={onToggle}>
                      {toggled ? (
                        <Icon name="ArrowUp" size="14" />
                      ) : (
                        <Icon name="ArrowDown" size="14" />
                      )}
                    </Button>
                  </Flex>
                </Flex>

                {toggled && (
                  <Box py={3}>
                    <TelecommandExecutionDetails executedTelecommand={it} />
                  </Box>
                )}
              </Box>
            )}
          </Toggler>
        );
      })}
  </>
);

/***
 * Telecommand execution indicator component: Allows the user to visualize if the telecommand has been sent or
 * if there is an error.
 */
interface StatusIndicatorProps {
  status: TelecommandExecutionStatus;
}
const TelecommandSentStatusIndicator: SFC<StatusIndicatorProps> = ({
  status
}) => {
  if (status === TelecommandExecutionStatus.Sent) {
    return (
      <Text bold ml={1}>
        {status}
      </Text>
    );
  } else if (status === TelecommandExecutionStatus.NotSent) {
    return (
      <Text bold ml={1} color="status.danger">
        {status}
      </Text>
    );
  } else {
    return (
      <Text bold ml={1} color="status.warning">
        {status}
      </Text>
    );
  }
};

/***
 * Telecommand response indicator component: Allows the user to visualize if the telecommand's response has been
 * received or if there is a timeout. There are 4 possible states:
 * - TcNone (no response received)
 * - TcAck (successful response received)
 * - TcNack (error response received)
 * - TcTimeout (Response timed out)
 */
interface TelecommandResponseIndicatorProps {
  responseStatus: TelecommandResponseStatus | null;
}
const TelecommandResponseIndicator: SFC<TelecommandResponseIndicatorProps> = ({
  responseStatus
}) => {
  const errorStates = [
    TelecommandResponseStatus.TcNack,
    TelecommandResponseStatus.TcTimeout
  ];
  if (responseStatus === TelecommandResponseStatus.TcAck) {
    return (
      <Text bold ml={1}>
        {responseStatus}
      </Text>
    );
  } else if (responseStatus != null && errorStates.includes(responseStatus)) {
    return (
      <Text bold ml={1} color="status.danger">
        {responseStatus}
      </Text>
    );
  } else {
    return (
      <Text bold ml={1} color="status.warning">
        {responseStatus}
      </Text>
    );
  }
};

/***
 * Quick action buttons to allow to re-use or re-send telecommands from the telecommand list
 */
interface ButtonWrapperProps {
  onClick: () => void;
}
const ResendButton: SFC<ButtonWrapperProps> = ({ onClick }) => (
  <Button size="small" onClick={onClick}>
    Re-send
  </Button>
);
const ReuseButton: SFC<ButtonWrapperProps> = ({ onClick }) => (
  <Button size="small" onClick={onClick}>
    Re-use
  </Button>
);

/***
 * Component that renders the details of a telecommand when the user expands a particular item from the list.
 * If the component has a reponse, then that is fetched from the telemetry service and presented to the user.
 */
interface TelecommandExecutionDetailsProps {
  executedTelecommand: ExecutedTelecommand;
  clearTcResponse: () => void;
  fetchTcResponse: (satelliteId: Number, executedTelecommandId: Number) => void;
  response?: TelemetryFrame;
}

class BaseTelecommandExecutionDetails extends Component<
  TelecommandExecutionDetailsProps
> {
  componentDidMount() {
    this.updateResponsePayload();
  }

  componentDidUpdate(prevProps: TelecommandExecutionDetailsProps) {
    if (
      this.props.executedTelecommand.id !== prevProps.executedTelecommand.id
    ) {
      this.updateResponsePayload();
    }
  }

  render() {
    const { response } = this.props;
    if (response) {
      return this.renderPayloadAndResponse();
    } else {
      return this.renderJustPayload();
    }
  }

  private updateResponsePayload() {
    const {
      executedTelecommand,
      fetchTcResponse,
      clearTcResponse
    } = this.props;
    const isSyncTc = executedTelecommand.tcType === TelecommandType.Sync;
    const statesWithResponse = [
      TelecommandResponseStatus.TcAck,
      TelecommandResponseStatus.TcNack
    ];
    const hasResponse =
      executedTelecommand.response != null &&
      statesWithResponse.includes(executedTelecommand.response);
    if (isSyncTc && hasResponse) {
      clearTcResponse();
      fetchTcResponse(executedTelecommand.satId, executedTelecommand.id);
    } else {
      clearTcResponse();
    }
  }

  private renderJustPayload() {
    const { executedTelecommand } = this.props;
    return (
      <Box bg="fill.1" p={2}>
        <TabInput
          record={executedTelecommand}
          tabs={["Input", "Raw Input"]}
          containers={(value: any, record: any) => (
            <>
              {value === 0 && this.renderUserInput(record)}
              {value === 1 && this.renderRawInput(record)}
            </>
          )}
        />
      </Box>
    );
  }

  private renderUserInput(payload: ExecutedTelecommand) {
    return (
      <pre>{JSON.stringify(payload.telecommandExecutionPayload, null, 2)}</pre>
    );
  }

  private renderRawInput(payload: any) {
    return (
      <pre>{JSON.stringify(payload.telecommandProcessedPayload, null, 2)}</pre>
    );
  }

  private renderPayloadAndResponse() {
    const { response, executedTelecommand } = this.props;
    const showPrettifiedResponse =
      response && response.header && Object.keys(response.header).length !== 0;
    const tabs = ["Raw Response", "Input", "Raw Input"];
    showPrettifiedResponse && tabs.unshift("Response");
    return (
      <Box bg="fill.1" p={2} data-tesid="BaseTelecommandExecutionDetails">
        <TabInput
          record={executedTelecommand}
          tabs={tabs}
          containers={(value: any, record: any) => (
            <>
              {value === tabs.indexOf("Response") &&
                showPrettifiedResponse &&
                response &&
                this.renderResponse(response)}
              {value === tabs.indexOf("Raw Response") &&
                response &&
                this.renderRawResponse(response)}
              {value === tabs.indexOf("Input") && this.renderUserInput(record)}
              {value === tabs.indexOf("Raw Input") &&
                this.renderRawInput(record)}
            </>
          )}
        />
      </Box>
    );
  }

  private renderResponse(response: TelemetryFrame) {
    const frame = AuroraTelemetryFrame.of(response);
    return (
      <>
        <p>Frame name: {response.frameName}</p>
        <p>Frame id: {response.frameId}</p>
        <p>Receive time: {response.timestamp}</p>
        <FramePayloadAuxiliariesGrid tmFrame={frame} />
        <FramePayloadBodyGrid tmFrame={frame} />
      </>
    );
  }

  private renderRawResponse(response: TelemetryFrame) {
    return <pre>{JSON.stringify(response, null, 2)}</pre>;
  }
}

const mapStateToProps = (
  state: any,
  ownProps: TelecommandExecutionDetailsProps
) => ({
  response: state.telecommandList.responses[ownProps.executedTelecommand.id]
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  clearTcResponse: () => {
    dispatch(clearTelecommandResponse());
  },
  fetchTcResponse: (satelliteId: Number, executedTelecommandId: Number) => {
    dispatch(fetchTelecommandResponse(satelliteId, executedTelecommandId));
  }
});

const TelecommandExecutionDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseTelecommandExecutionDetails);
