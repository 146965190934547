import React, { FC } from "react";
import styled from "styled-components";
import ReSelect, { components } from "react-select";
import {
  COMMON,
  TYPOGRAPHY,
  BORDER,
  TypographyProps,
  CommonProps,
  BorderProps
} from "styles/constants";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";
import { themeGet } from "styled-system";

interface HTMLSelect extends React.SelectHTMLAttributes<HTMLSelectElement> {}
interface SelectProps
  extends TypographyProps,
    CommonProps,
    BorderProps,
    TestableProps {
  options: any;
  onChange?: any;
  customOption?: any;
}

const SelectStyled = styled(ReSelect)`
  ${COMMON}
  ${TYPOGRAPHY}
  ${BORDER}
  text-transform: uppercase;
  text-align: center;
  display: block;
  padding: 0;
  width: 100%;
  appearance: none;
  background-color: transparent;
  border-radius: 0;


  &:focus {
    outline: none;
    box-shadow: none;
  }
  .custom-select__control {
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  .custom-select__indicator-separator {
    background-color: none;
    margin: 0;
    border-right: 0.5px dashed;
    border-color: ${themeGet("colors.palette.blue.2")};
  }

  .custom-select__menu {
    margin: 0;
    border-radius: 0;
    background-color: ${themeGet("colors.palette.blue.3")};
    text-align: left;
  }

  .custom-select__single-value,
  .custom-select__placeholder,
  .custom-select__input
   {
    color: ${themeGet("colors.palette.brand.0")};
  }
`;

const CustomSelect = (props: HTMLSelect & SelectProps) => {
  const expandedProps: any = { ...props };

  if (props.customOption) {
    const customOption: FC<any> = (optionProps: any) => {
      return (
        <components.Option {...optionProps}>
          {<props.customOption {...optionProps.data} />}
        </components.Option>
      );
    };

    expandedProps.components = { Option: customOption };
  }

  return <SelectStyled classNamePrefix="custom-select" {...expandedProps} />;
};

CustomSelect.displayName = "CustomSelect";

CustomSelect.defaultProps = {
  fontWeight: "bold",
  color: "inherit",
  fontSize: 2,
  py: 2,
  pl: 2,
  pr: 5,
  border: "none",
  [DATA_TEST_ID_ATTR_NAME]: CustomSelect.displayName
};

export default CustomSelect;
