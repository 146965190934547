import {
  dataProvider,
  GET_LIST,
  CREATE,
  GET_ONE,
  UPLOAD,
  telemetryDataProvider
} from "app/network";

/**
 * @param {number} satelliteId
 */
export const getTelecommandSpecs = (satelliteId) => {
  return dataProvider(GET_LIST, `satellite/${satelliteId}/telecommand-specs`);
};

/**
 * @param {number, object} satelliteId, telecommandData
 */
export const execTelecommand = (satelliteId, data) => {
  return dataProvider(CREATE, `satellite/${satelliteId}/telecommands`, {
    data
  });
};

/**
 * @param {number, object} satelliteId, telecommandData
 */
export const uploadTelecommand = (satelliteId, data) => {
  return dataProvider(UPLOAD, `satellite/${satelliteId}/telecommands`, {
    data
  });
};

/**
 * @param {number} satelliteId
 */
export const getExecTelecommands = (satelliteId, pagination) => {
  return dataProvider(GET_LIST, `satellite/${satelliteId}/telecommands`, {
    pagination
  });
};

export const getSyncTelecommandResponse = (
  satelliteId,
  executedTelecommandId
) => {
  return telemetryDataProvider(
    GET_ONE,
    `satellite/${satelliteId}/telecommand-response`,
    { id: executedTelecommandId }
  );
};

/**
 * @param {number, object} satelliteId, telecommandData
 */
export const uploadTelecommandFile = (satelliteId, data) => {
  return dataProvider(UPLOAD, `satellite/${satelliteId}/telecommands/file`, {
    data
  });
};
