import React, { SFC, ChangeEvent, FunctionComponent } from "react";
import { Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import { Box, Grid, Flex, Button } from "primitives";
import { CustomSelect, Radio, CancelButton } from "components";
import { TelecommandExecutionMode } from "../models";

/**
 * Telecommand selection component family
 */

interface TcSelectionProps {
  telecommandSpecs: any;
  selectTelecommandAction: (telecommandId: string) => void;
  currentTelecommandId: string;
}

export const TelecommandSelectionStep: SFC<TcSelectionProps> = ({
  telecommandSpecs,
  selectTelecommandAction,
  currentTelecommandId
}) => {
  const options =
    telecommandSpecs &&
    telecommandSpecs.map((telecommand: any) => {
      return { value: telecommand.id, label: telecommand.label };
    });

  const currentValue =
    telecommandSpecs &&
    telecommandSpecs.filter((telecommand: any) => {
      return telecommand.id === currentTelecommandId;
    })[0];

  return (
    <Grid data-testid="TelecommandSelectionStep" overflow="visible">
      {!telecommandSpecs ? (
        <p>Telecommands are not ready</p>
      ) : (
        <CustomSelect
          placeholder="Select Telecommand"
          onChange={(e: any) => {
            selectTelecommandAction(e.value);
          }}
          value={currentValue}
          border={1}
          options={options}
          color="text.default"
          autoFocus={true}
        />
      )}
    </Grid>
  );
};

/**
 * Telecommand execution mode component family
 */
interface TcExecutionModeSelectorProps {
  selectModeAction: (executionMode: string) => void;
  executionMode: TelecommandExecutionMode;
  classes: any;
}

export const TelecommandExecutionModeStep: SFC<
  TcExecutionModeSelectorProps
> = ({ selectModeAction, executionMode }) => {
  const modes = Object.values(TelecommandExecutionMode);
  return (
    <Grid
      m={1}
      gridTemplateColumns="1fr 1fr 1fr"
      data-testid="BaseTelecommandExecutionModeStep"
    >
      {modes &&
        modes.map((mode) => (
          <Box key={`telecommand-execution-${mode}`}>
            <Radio
              name="sendTc"
              value={mode}
              label={mode}
              checked={mode === executionMode}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                selectModeAction(event.target.value);
              }}
            ></Radio>
          </Box>
        ))}
    </Grid>
  );
};

/**
 * Telecommand send confirmation
 */

type TelecommandSendConfirmationProps = {
  modalOpen: boolean;
  sendTelecommand: any;
  cancel: any;
};

export const TelecommandSendConfirmation: FunctionComponent<
  TelecommandSendConfirmationProps
> = ({ modalOpen, sendTelecommand, cancel }) => {
  return (
    <Dialog fullWidth={true} open={modalOpen} maxWidth="sm">
      <DialogTitle>Send telecommand?</DialogTitle>
      <DialogActions>
        <Flex flexDirection="row" justifyContent="flex-end" alignItems="center">
          <Button autoFocus={true} onClick={(e) => sendTelecommand()} mr={1}>
            Send
          </Button>
          <CancelButton onClick={() => cancel()}>Cancel</CancelButton>
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
