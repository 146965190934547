import React, { Component } from "react";
import { Box, Text } from "primitives";
import {
  execTelecommand,
  getTelecommandSpecs,
  uploadTelecommand
} from "../services";
import { DashboardComponents } from "app/dashboard/models";
import { connect } from "react-redux";
import {
  TelecommandSelectionStep,
  TelecommandExecutionModeStep
} from "./TelecommandExecutionHelpers";
import { TelecommandCreationForm } from "./TelecommandExecutionForm";
import { TelecommandFileUpload } from "./TelecommandExecutionUpload";
import { TelecommandTerminal } from "./TelecommandExecutionTerminal";
import { TelecommandExecutionMode, AuroraTelecommandPayload } from "../models";
import {
  selectTelecommand,
  selectTelecommandExecutionMode,
  clearSelectedTelecommand
} from "../actions";

class TelecommandExecution extends Component {
  constructor(props) {
    super(props);
    this.willUnmount = false;
    this.state = {
      telecommandSpecs: null,
      currentTelecommandSpec: null,
      file: null,
      loading: false
    };

    this.selectTelecommandAction = this.selectTelecommandAction.bind(this);
    this.selectModeAction = this.selectModeAction.bind(this);
    this.submitTelecommand = this.submitTelecommand.bind(this);
    this.uploadTelecommand = this.uploadTelecommand.bind(this);
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  selectTelecommandAction(telecommandId) {
    const { dispatchTelecommandSelectAction } = this.props;
    dispatchTelecommandSelectAction(telecommandId);
    this.updateCurrentTelecommandSpec(telecommandId);
  }

  selectModeAction(mode) {
    const { dispatchTelecommandExecutionModeAction } = this.props;
    dispatchTelecommandExecutionModeAction(mode);
  }

  submitTelecommand(data) {
    const { satellite, selectedPassage } = this.props;
    const { currentTelecommandSpec } = this.state;
    const telecommandExecutionPayload = AuroraTelecommandPayload.from(
      currentTelecommandSpec,
      data.formData ? data.formData : data
    );
    if (selectedPassage && selectedPassage.passageID) {
      telecommandExecutionPayload.setPassageId(selectedPassage.passageID);
      try {
        return execTelecommand(
          satellite.id,
          telecommandExecutionPayload.toOutputModel()
        );
      } catch (error) {
        console.log(`Error sending telecommand`, error);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error("Error sending telecommand: no ground station id defined"); // TODO: we might want to show an alert to the user
    }
  }

  uploadTelecommand(file) {
    const { satellite } = this.props;
    const data = new FormData();
    data.append("data", file);
    uploadTelecommand(satellite.id, data);
  }

  updateCurrentTelecommandSpec(telecommandId) {
    const { telecommandSpecs } = this.state;
    if (!telecommandSpecs) return;
    const spec = telecommandSpecs.find(
      (telecommand) => telecommand.id === telecommandId
    );
    this.setState({ currentTelecommandSpec: spec });
  }

  componentWillMount() {
    const { options } = this.props;
    if (options && options.defaults && options.defaults.fill) {
      this.selectModeAction(options.defaults.fill);
    } else {
      this.selectModeAction(TelecommandExecutionMode.Form);
    }
  }

  componentDidMount() {
    const { satellite } = this.props;
    this.props.clearSelectedTelecommand();

    getTelecommandSpecs(satellite.id)
      .then((response) => {
        if (!this.willUnmount && response && response.data) {
          this.setState({ telecommandSpecs: response.data }, () => {
            if (this.props.selectedTelecommandId) {
              this.updateCurrentTelecommandSpec(
                this.props.selectedTelecommandId
              );
            }
          });
        }
      })
      .catch((error) => {
        this.setState({ telecommandSpecs: null });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedTelecommandId !== this.props.selectedTelecommandId) {
      this.updateCurrentTelecommandSpec(this.props.selectedTelecommandId);
    }
    const { options, selectedExecutionMode } = this.props;
    if (!selectedExecutionMode) {
      if (options && options.defaults && options.defaults.fill) {
        this.selectModeAction(options.defaults.fill);
      } else if (prevProps.selectedExecutionMode) {
        this.selectModeAction(prevProps.selectedExecutionMode);
      } else {
        this.selectModeAction(TelecommandExecutionMode.Form);
      }
    }
  }

  render() {
    const {
      selectedTelecommandId,
      selectedExecutionMode,
      formData,
      selectedTelecommandRecordId,
      options,
      satellite
    } = this.props;
    const { telecommandSpecs, currentTelecommandSpec } = this.state;

    return (
      <>
        {options.label && (
          <Text fontSize={18} m="10px 0">
            {options.label}
          </Text>
        )}
        <Box
          bg="fill.0"
          p={2}
          overflow="visible"
          data-testid="TelecommandExecution"
        >
          <TelecommandSelectionStep
            telecommandSpecs={telecommandSpecs}
            selectTelecommandAction={this.selectTelecommandAction}
            currentTelecommandId={selectedTelecommandId}
          />
          {selectedTelecommandId && (
            <TelecommandExecutionModeStep
              selectModeAction={this.selectModeAction}
              executionMode={selectedExecutionMode}
              options={options}
            />
          )}

          {selectedTelecommandId &&
            selectedExecutionMode === TelecommandExecutionMode.JsonUpload && (
              <TelecommandFileUpload
                uploadTelecommand={this.uploadTelecommand}
              />
            )}

          {selectedTelecommandId &&
            selectedExecutionMode === TelecommandExecutionMode.Form && (
              <TelecommandCreationForm
                key={selectedTelecommandRecordId}
                initialFormData={formData || null}
                telecommandSpec={currentTelecommandSpec}
                onSubmitTelecommandHandler={this.submitTelecommand}
                satelliteId={satellite.id}
                defaultFieldsVisualization={
                  options && options.defaults && options.defaults.visualization
                    ? options.defaults.visualization
                    : null
                }
                automaticFill={!(options.defaults.automatic === false)}
                sendConfirmation={options.send.sendConfirmation === true}
                preventMultiSend={!(options.send.preventMultiSend === false)}
              />
            )}

          {selectedTelecommandId &&
            selectedExecutionMode === TelecommandExecutionMode.Terminal && (
              <TelecommandTerminal
                key={selectedTelecommandRecordId}
                telecommandSpec={currentTelecommandSpec}
                onSubmitTelecommandHandler={this.submitTelecommand}
                automaticFill={!(options.defaults.automatic === false)}
                sendConfirmation={options.send.sendConfirmation === true}
                preventMultiSend={options.send.preventMultiSend === true}
              />
            )}
        </Box>
      </>
    );
  }
}

TelecommandExecution.defaultProps = {
  ...DashboardComponents.TelecommandsExecution,
  label: ""
};

const mapStateToProps = (state) => {
  return {
    selectedTelecommandId: state.telecommandExecution.telecommandId,
    selectedTelecommandRecordId: state.telecommandExecution.telecommandRecordId,
    formData: state.telecommandExecution.telecommandFormData,
    selectedExecutionMode: state.telecommandExecution.telecommandExecutionMode,
    selectedPassage: state.visibilityWindow.selectedPassage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchTelecommandSelectAction: (telecommandId) =>
      dispatch(selectTelecommand(telecommandId)),

    dispatchTelecommandExecutionModeAction: (executionModeValue) => {
      try {
        let executionMode;
        switch (executionModeValue) {
          case TelecommandExecutionMode.Form:
            executionMode = TelecommandExecutionMode.Form;
            break;
          case TelecommandExecutionMode.JsonUpload:
            executionMode = TelecommandExecutionMode.JsonUpload;
            break;
          case TelecommandExecutionMode.Terminal:
            executionMode = TelecommandExecutionMode.Terminal;
            break;
          default:
            throw Error(
              `Unknown telecommand execution mode ${executionModeValue}. Supported values are: ${Object.values(
                TelecommandExecutionMode
              )}`
            );
        }

        dispatch(selectTelecommandExecutionMode(executionMode));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    },
    clearSelectedTelecommand: () => dispatch(clearSelectedTelecommand())
  };
};

export const TelecommandsExecution = connect(
  mapStateToProps,
  mapDispatchToProps
)(TelecommandExecution);
